import React from 'react';

const Bottomd = ({ something }) => { 
    return (
        <div className="Bottom">
            <p>All Rights Reserved 🐕 2023-2025</p>
        </div>
    );
};

export default Bottomd;