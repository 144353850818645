import React from 'react';
import { Link } from "react-router-dom";
import snow from './snow.png';

const topper = ({ title }) => {
    return (
      <div>
        <p class="brand-text">Digital Snow</p>
        <img src={snow} width={90} height={90} alt="snow" />
        
        <nav class="center-nav">
          <Link className="nav-button" to="/">home</Link>
          <Link className="nav-button" to="/team">team</Link>
          <Link className="nav-button" to="/product">product</Link>
          <Link className="nav-button" to="/contact">contact</Link>
          <Link className="nav-button" to="/privacy">privacy</Link>
        </nav>
      </div>
    );
  };
  
  export default topper;