import './App.css';
import shib from './shib.png';
import Topper from './topper';
import Bottomd from './bottomd';


function App() {

  return (
    <div className="App">
      <header className="App-header">
        <div className="branding-logo">
          <Topper />
        </div>
      </header>

      <div className="Welcome-new-main">
          <p>find your flaws before attackers do</p>
        </div>

      <img src={shib} width={320} height={320} alt="shib" />

      <div className="App-body">
      <p>DS 🏴‍☠️</p>
      </div>
        <div>
          <Bottomd />
        </div>
    </div>




  );
}

export default App;
