import React from 'react'
import Topper from '../topper';
import Bottomd from '../bottomd';

  function Privacy() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="branding-logo">
          <Topper />
        </div>
      </header>

      <div className="Welcome-new-main">
          <p>privacy policy</p>
        </div>
      <div className="App-body">
        <div class="collection-defs">
        <h2>definitions</h2>
          <p>Digital Snow ("we, us, company, website")<br />
          Cloudflare ("waf, other security system")<br />
          Herotofu contact form ("form")<br />
          Visitor or submitter ("user, you")</p>
        </div>
        
        <h2>information collection and use</h2>
          <div class="collection-item">We collect personal information including name, email address and any additional information that you choose to share. This information helps us improve our services to provide relevant communications to you.</div>

        <h2>marketing communications</h2>
          <div class="collection-item">By submitting your email, you consent to receive marketing communications from us. You can unsubscribe at any time using the link provided in each email.</div>

        <h2>acceptance of privacy policy</h2>
          <div class="collection-item">Continued use of our website, apps, and our form constitutes acceptance of these privacy policies.</div>

        <h2>personal information we collect</h2>
          <div class="collection-item">Name, email, any personal details you choose to enter into our "share some details" section of the contact form</div><br />
          <div class="collection-item">IP address</div><br />
          <div class="collection-item">Geolocation (estimated)</div><br />
          <div class="collection-item">Browser user agent details</div><br />
          <div class="collection-item">Details are collected when accessing the website by our waf</div><br />
          <div class="collection-item">Details are collected when you submit our contact form which is on demand and not automatically collected unless you click the submit button</div>
        
        <h2>how we use your information</h2>
          <div class="collection-item">We use information collected about you to protect our website and apps</div>
          <div class="collection-item">We also use information collected about you to contact you to follow up on your requests submitted through our contact form</div>

        <h2>information sharing</h2>
          <div class="collection-item">We only share information with two third-party companies in order to support the functions mentioned above such as protecting our website and apps as well as to follow up with you on your request to contact us.</div>

        <h2>user control and data rights</h2>
          <div class="collection-item">Users can control what data is submitted in our contact form by only including name, email and non-sensitive information for inquiries about our company's offerings or questions related to your data</div>
          <div class="collection-item">Users can contact the company through the contact form to request a data erasure which will be completed within 1 week or faster depending on local jurisdiction or local privacy laws. Note: This applies to name and email only. For other privacy concerns, please use our contact form</div>
    
        <h2>cookies policy</h2>
          <div class="collection-item">Cookies are not collected in order to respect your privacy and browsing behaviors</div>
        <br />

        <div class="Welcome-new-main">last update: dec 26, 2024</div>

      </div>
    <div>
          <Bottomd />
        </div>
    </div>
  )
  }

export default Privacy;
