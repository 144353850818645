import React from 'react'
import Topper from '../topper';
import Bottomd from '../bottomd';

function Team() {
  return (
    <div className="App">

      <header className="App-header">
        <div className="branding-logo">
          <Topper />
        </div>
      </header>

      <div className="Welcome-new-main">
          <p>operating principles</p>
        </div>

      <div className="App-body">

        <p class="team-text">At Digital Snow, our mission is clear and resolute: safeguarding the privacy of individuals and companies. We're a dedicated force committed to empowering users and organizations to triumph over every day cyber threats. With a foundation rooted in Silicon Valley and a collective wealth of experience from big tech corporations, we bring a dynamic blend of expertise to the cybersecurity realm, including a deep understanding of the unique challenges faced by startups in safeguarding their digital presence.</p>

        <p class="team-text">The cyber landscape is in a constant state of flux, and so are we. We evolve alongside the ever-changing threats. Our commitment to innovation means that our solutions are not just effective today but are also future-proofed for the challenges of tomorrow.</p>

        <p class="team-text">Privacy isn't just a buzzword; it's a guiding principle. We believe in the fundamental right to digital privacy and have made it our mission to protect it. Our solutions are crafted with the user in mind, ensuring robust defenses without restricting personal freedoms.</p>
      </div>
      <div>
          <Bottomd />
      </div>
    </div>
  )
}

export default Team