import React, { useState } from 'react';
import Topper from '../topper';
import Bottomd from '../bottomd';

const productData = {
  pen_testing: [
    { id: 1, name: 'Pentest', description: 'Identify and address security vulnerabilities before malicious actors can exploit them. Our expert pen testing team simulates real-world cyberattacks' },
    { id: 2, name: 'Remediation', description: 'Uncover weaknesses in your network, applications and systems. We provide actionable remediation advice and help you meet compliance requirements' },
  ],
  security_assessment: [
    { id: 3, name: 'Assessment', description: 'Security assessments are systematic evaluations designed to identify and mitigate vulnerabilities within an organizations information systems and processes. These assessments include a variety of methodologies such as penetration testing, vulnerability scanning, and risk assessments' },
    { id: 4, name: 'Detailed Findings', description: 'Security assessments provide a systematic evaluation of your company network infrastructure and applications to identify vulnerabilities and enhance overall security posture' },
  ],
  threat_hunting: [
    { id: 5, name: 'Proactive Threat Hunt', description: 'Stay ahead of sophisticated cyber threats with our advanced threat hunting solutions. Our expert team employs cutting-edge techniques and technologies to actively search for hidden adversaries within your network. Enhance your cybersecurity posture, reduce dwell time, and gain peace of mind with our proactive threat hunting services' },
  ],
};

const Product = () => {
  const [selectedCategory, setSelectedCategory] = useState('pen_testing');

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="branding-logo">
          <Topper />
        </div>
      </header>

      <div className="Welcome-new-main">
        <p>current security offerings</p>
      </div>

      <div className="product-container">
        <div className="sidebar">
          {Object.keys(productData).map((category, index) => (
            <div
              key={index}
              className={`category ${selectedCategory === category ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category)}
            >
              {category.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())} {/* Convert to readable format */}
            </div>
          ))}
        </div>

        <div className="product-display">
          {productData[selectedCategory].map((product) => (
            <div key={product.id} className="product-box">
              <h3>{product.name}</h3>
              <p>{product.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div>
          <Bottomd />
        </div>
    </div>
  );
};

export default Product;
